<template>
    <v-container>
        <v-row align="center" justify="center">
            <v-col cols="auto" class="app-footer__terms-column">
                copyright
                <v-icon class="app-footer__terms-copy-icon mx-1">
                    $vuetify.icons.copyright
                </v-icon>
                {{ new Date().getFullYear() }} Sydney Kids Neuropsychology
            </v-col>
            <v-col cols="auto" class="app-footer__terms-column">
                <router-link to="/terms" class="app-footer__terms-anchor">
                    terms &amp; conditions
                </router-link>
            </v-col>
            <v-col cols="auto" class="app-footer__terms-column">
                <v-btn
                    text
                    small
                    rounded
                    class="app-footer__terms-back"
                    @click="scrollToTop"
                >
                    back to top
                    <v-icon class="ml-2">
                        $vuetify.icons.chevron
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'FooterTerms',
    methods: {
        scrollToTop() {
            document.getElementById('app').scrollIntoView();
        }
    }
};
</script>

<style scoped lang="scss">
.app-footer {
    &__terms-column {
        color: var(--v-primary-darken4);
        flex: 0 0 auto;
        font-size: 14px;
        padding: 0 gutter-size(2);
    }
    &__terms-copy-icon {
        color: var(--v-primary-darken4);
        font-size: $font-size-xs;
    }
    &__terms-anchor {
        color: var(--v-primary-darken4);
        #{$interact} {
            text-decoration: none;
        }
    }
    &__terms-back {
        color: var(--v-primary-darken4);
        font-size: 12px;
        height: auto !important;
    }
}
</style>
