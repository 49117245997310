import { render, staticRenderFns } from "./FooterTerms.vue?vue&type=template&id=ba9b362c&scoped=true"
import script from "./FooterTerms.vue?vue&type=script&lang=js"
export * from "./FooterTerms.vue?vue&type=script&lang=js"
import style0 from "./FooterTerms.vue?vue&type=style&index=0&id=ba9b362c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba9b362c",
  null
  
)

export default component.exports