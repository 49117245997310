<template>
    <v-footer
        id="app-footer"
        class="app-footer"
        app
        fixed
        padless
    >
        <v-container class="text-center pa-0" fluid>
            <v-row no-gutters>
                <v-col cols="12">
                    <footer-navigation />
                </v-col>
                <v-col cols="12" class="app-footer__terms-row">
                    <footer-terms />
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
import FooterNavigation from '@/components/global/FooterNavigation';
import FooterTerms from '@/components/global/FooterTerms';
export default {
    name: 'TheFooter',
    components: {
        FooterNavigation,
        FooterTerms
    }
};
</script>

<style scoped lang="scss">
.app-footer {
    background-color: var(--v-primary-base) !important;
    z-index: 0;
    &__terms-row {
        background: rgba(0, 0, 0, 0.1);
    }
}
</style>
