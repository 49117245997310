import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import './fontawesome';

Vue.use(Vuetify);

export default new Vuetify({
    breakpoint: {
        thresholds: {
            xs: 768,
            sm: 1024,
            md: 1280,
            lg: 1920,
            xl: 1921
        }
    },
    icons: {
        iconfont: 'faSvg',
        values: {
            academic: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'graduation-cap']
                }
            },
            assessments: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'pencil-alt']
                }
            },
            attention: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'cogs']
                }
            },
            autism: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'child']
                }
            },
            checkboxOff: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'square']
                }
            },
            checkboxOn: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'check-square']
                }
            },
            chevron: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'angle-up']
                }
            },
            comment: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'comment-dots']
                }
            },
            comprehensive: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'brain']
                }
            },
            contact: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'envelope']
                }
            },
            copyright: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'copyright']
                }
            },
            dropdown: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'angle-down']
                }
            },
            exclamation: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'exclamation-triangle']
                }
            },
            facebook: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fab', 'facebook-f']
                }
            },
            faqs: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'question']
                }
            },
            home: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'home']
                }
            },
            intellectual: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'lightbulb']
                }
            },
            map: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'map-marker-alt']
                }
            },
            menu: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'bars']
                }
            },
            phone: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'phone']
                }
            },
            quote: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'quote-left']
                }
            },
            school: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'book']
                }
            },
            share: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'share-square']
                }
            },
            success: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'check-circle']
                }
            },
            twitter: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fab', 'twitter']
                }
            },
            user: {
                component: 'font-awesome-icon',
                props: {
                    icon: ['fas', 'user']
                }
            }
        }
    },
    theme: {
        themes: {
            light: {
                academic: '#20a7dd',
                accent: '#82B1FF',
                anchor: '#000000',
                attention: '#7F67A4',
                autism: '#F7DF41',
                comprehensive: '#dd276b',
                error: '#FF5252',
                info: '#2196F3',
                intellectual: '#8CC38F',
                primary: '#0076a7',
                school: '#56B5B6',
                secondary: '#424242',
                success: '#4CAF50',
                warning: '#FFC107'
            }
        },
        options: {
            customProperties: true
        }
    }
});
